body {
  margin: 0;
  padding: 8px;
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: white;
  background: linear-gradient(to right, #2d009a, #c100aa);
}

* {
  box-sizing: border-box;
}
