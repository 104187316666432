#root {
  margin-bottom: 100px; /* for privacy notice */
}

a.unstyled {
  color: white;
}

button,
a:not(.unstyled) {
  background: white;
  color: blue;
  display: inline-block;
  margin: 0;
  padding: 2px 4px;
  border: 1px solid white;
  text-decoration: none;
  font-family: monospace;
}

button:hover,
a:not(.unstyled):hover {
  cursor: pointer;
  background: transparent;
  color: white;
}

pre {
  margin: 0;
}

#a {
  position: absolute;
  top: 8px;
  right: 8px;
}

#a a {
  padding: 8px;
  font-size: 16px;
  background: transparent;
  color: white;
}

#a a:hover {
  background: white;
  color: blue;
}

#sponsor {
  position: absolute;
  top: 8px;
  left: 8px;
}

#sponsor a {
  padding: 8px;
  font-size: 16px;
  background: transparent;
  color: white;
}

#sponsor a:hover {
  background: white;
  color: red;
}

#sponsor .icon {
  font-size: 10px;
}

.page {
  position: relative;
  padding: 0 8px;
  max-width: 1200px;
  margin: auto;
}

header {
  padding-top: 64px;
  text-align: center;
  margin-bottom: 16px;
}

#logo a {
  color: white;
  text-decoration: none;
}

h1 {
  margin-top: 0;
  line-height: 36px;
  font-size: 48px;
}

#blurb {
  font-size: 24px;
  margin-bottom: 24px;
}

#instructions {
  text-align: center;
  font-size: 16px;
  margin: 16px auto;
  text-align: left;
  display: flex;
  justify-content: center;
}

#instructions ul {
  display: inline-block;
}

main {
  max-width: 1200px;
  margin: auto;
  padding: 0 24px;
}

main h2 {
  text-align: center;
  font-size: 24px;
}

.windows > * {
  max-width: 602px !important;
  margin: auto;
  margin-bottom: 32px;
  overflow: hidden !important;
}

.windows > * > * {
  overflow: auto !important;
}

.browser-content {
  display: block;
  max-width: 100%;
}

.windows .prism-code {
  padding: 16px !important;
  font-size: 16px !important;
}

.window-blurb {
  text-align: center;
  font-size: 16px;
  position: relative;
  z-index: 10;
}

.terminal-code {
  position: relative;
  font-size: 16px;
}

.terminal-code .code-line {
  margin-bottom: 1em;
}

.terminal-code .code-line:last-child {
  margin-bottom: 0;
}

.terminal-code .copy {
  color: #ccc;
  padding-left: 24px;
}

.token.builtin {
  color: lightseagreen;
}

.command {
  color: lime;
}

.comment {
  color: #888;
}

.folder {
  color: #4ff;
}

.terminal-code .code-line {
  display: flex;
  justify-content: space-between;
}

.editor-code .copy {
  position: absolute;
  right: 4px;
  bottom: 4px;
  font-size: 16px;
  color: #ccc;
  background: rgb(42, 39, 52);
}

footer {
  margin-top: 24px;
  justify-self: end;
  /* display: flex;
    justify-content: space-between; */
  font-size: 16px;
  text-align: right;
}

#bookmarkleet-links {
  margin: 0;
  padding: 0;
}

#bookmarkleet-links li {
  list-style: none;
  float: left;
}

#bookmarkleet-links a {
  margin: 8px;
}

#bookmarkleet-links a,
#bookmarkleet-links button {
  padding: 8px;
}

.legal {
  font-size: 12px;
}

.privacyNotice {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: black;
  border-top: 1px solid white;
  padding: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.privacyNotice button {
  margin-left: 12px;
}
